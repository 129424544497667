import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "プロダクトへの「思い」を大切にし続ける開発チームを目指して〜メドレー開発本部合宿@まるも〜",
  "date": "2017-02-14T09:09:48.000Z",
  "slug": "entry/2017/02/14/180948",
  "tags": ["medley"],
  "hero": "./2017_02_14.png",
  "heroAlt": "product"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは、オンライン病気事典`}<a parentName="p" {...{
        "href": "https://medley.life/"
      }}>{`MEDLEY`}</a>{`の開発をしてます徐聖博です。
先日、開発本部のメンバーで千葉県の「`}<a parentName="p" {...{
        "href": "https://marumo.net/gasshuku-plan001/"
      }}>{`まるも`}</a>{`・`}<a parentName="p" {...{
        "href": "https://www.kajiyaryokan.com/"
      }}>{`かぢや旅館`}</a>{`」で合宿を行いました。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="開発合宿｜千葉県金谷のまるもで開発合宿しませんか？" src="https://hatenablog-parts.com/embed?url=http%3A%2F%2Fmarumo.net%2Fgasshuku-plan001%2F" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://marumo.net/gasshuku-plan001/">marumo.net</a></cite> // 『まるもの開発合宿プラン』を利用
    <p>{`今回は、開発本部で合宿を行った背景や、合宿の内容の紹介をしていきます。`}</p>
    <h1>{`背景`}</h1>
    <h2>{`ストイックすぎる大人たち`}</h2>
    <p>{`メドレーはスタートアップ企業の中では比較的年齢層が高く、一部からは「大人の`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%A5%D9%A5%F3%A5%C1%A5%E3%A1%BC"
      }}>{`ベンチャー`}</a>{`」と呼ばれることもあります。
中でも開発本部に関してはメンバー 17 人中、20 代は自分を含めて 3 人しかおらず、基本的に 30 代中盤の「大人(おっさん)」が集まる部署です。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="CLINICS のエンジニアリーダー田中さんに「聞いてみた」 by 平木 聡 |  メドレー平木の「気になるあの人に聞いてみた」" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.wantedly.com%2Fcompanies%2Fmedley%2Fpost_articles%2F50054" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.wantedly.com/companies/medley/post_articles/50054">www.wantedly.com</a></cite>
    <p>{`// 開発本部の`}<strong parentName="p">{`“ツートップ”`}</strong>{`による対談。`}</p>
    <p>{`普段はゆるく気さくな「大人」たちですが、仕事ではとことん真剣にプロダクトと向き合っています。`}</p>
    <p>{`例えば、昨年 5 月に行った開発合宿では、昼に集合し途中夕食・ボーリングを挟み深夜 1 時ごろまで開発し、次の日は朝ごはんを食べて開発、昼ごはんを食べて開発。`}</p>
    <iframe className="embed-card embed-blogcard" style={{
      "display": "block",
      "width": "100%",
      "height": "190px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="温泉浸かりながら開発（開発合宿@おんやど恵） - MEDLEY オフィシャルブログ" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fdeveloper.medley.jp%2Fentry%2F2016%2F05%2F30%2F165640" frameBorder="0" scrolling="no"></iframe><cite className="hatena-citation"><a href="https://developer.medley.jp/entry/2016/05/30/165640">develoepr.medley.jp</a></cite>
    <p>{`// 昨年 5 月末に『おんやど恵』で行った開発合宿。`}</p>
    <p>{`温泉に行ったにも関わらず 2 日間みっちり開発し、時間の限り最後までクオリティにこだわりやりきりました。`}</p>
    <h2>{`成長してゆく組織`}</h2>
    <p>{`そんな合宿を行った昨年の 5 月末の 12 人から、メドレー開発本部には新たに 5 人のエンジニア・デザイナーが加わり合計 17 人体制になりました。 オンライン診療アプリ CLINICS の`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/iOS"
      }}>{`iOS`}</a>{`版・`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/Android"
      }}>{`Android`}</a>{`版もリリースし、プロダクトの数も増え、そして社員数が約 1.5 倍になるなど会社全体としても成長をしました。`}</p>
    <p>{`会社の成長に伴い開発の業務が細分化し、「席は近いけど、やってることは全然違う」というような状況が増えました。
また、個人のプロダクトに対する思いを共有する場も日常の業務では少なくなってきました。`}</p>
    <p>{`メドレーにおけるプロダクトは、一つひとつ「思い」があり、その「思い」によって生まれたプロダクトです。
そのため、同じ部署のメンバーが互いにどのような思いでそのプロダクト開発しているかを知りながら働くことは、とても大切なことだと考えています。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="MEDLEY(メドレー) オンライン病気事典" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fmedley.life%2F" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://medley.life/">medley.life</a></cite>
    <p>{`// 正しい医療の情報を発信し、病気で不安を感じる人を一人でも減らしたい「思い」で生まれた MEDLEY`}</p>
    <p>{`こうした背景の中、「リフレッシュ・コミュニケーション」をテーマに開発本部合宿を行いました。`}</p>
    <h1>{`何をやったか`}</h1>
    <p>{`今回、行った先は`}<a parentName="p" {...{
        "href": "https://www.google.co.jp/maps/place/%E5%8D%83%E8%91%89%E7%9C%8C%E5%AF%8C%E6%B4%A5%E5%B8%82/@35.2490826,139.800212,12z/data=!3m1!4b1!4m5!3m4!1s0x60180e34a8ff9b05:0x18f16ebde5b12939!8m2!3d35.3041204!4d139.8571048?hl=ja"
      }}>{`千葉県富津市`}</a>{`。`}</p>
    <p>{`当日は強風に見舞われ、当初予定していた海釣りから予定変更し、鋸山へと行きました。`}</p>
    <p>{`鋸山は標高 329.4m と、決して高くはありません。
しかし、登ってみると意外と階段が多く、普段おっさんたちが発しないような声色で「つらい」・「え、まだ登るの？」と音を上げながらもなんとか巡回コースを歩きました。
各々プライベートことも含め語り合いながら、そして苦労の末に山頂にたどり着き、そこで撮った集合写真はみなとてもいい笑顔で登ってよかったと思いました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20170208/20170208113439.jpg",
        "alt": "f:id:yamadagenki:20170208113439j:plain",
        "title": "f:id:yamadagenki:20170208113439j:plain"
      }}></img>{`
// 名物の地獄覗きを体験。撮影は高所恐怖症の`}<a parentName="p" {...{
        "href": "https://www.wantedly.com/companies/medley/post_articles/42315"
      }}>{`不惑エンジニア`}</a>{`) 。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20170208/20170208113501.jpg",
        "alt": "f:id:yamadagenki:20170208113501j:plain",
        "title": "f:id:yamadagenki:20170208113501j:plain"
      }}></img>{` // 鋸山山頂で撮った記念写真。半分以上のメンバーは翌日筋肉痛になりました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20170208/20170208113522.jpg",
        "alt": "f:id:yamadagenki:20170208113522j:plain",
        "title": "f:id:yamadagenki:20170208113522j:plain"
      }}></img>{` // おみくじを引き、今年の運勢を占ったり。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20170208/20170208113559.jpg",
        "alt": "f:id:yamadagenki:20170208113559j:plain",
        "title": "f:id:yamadagenki:20170208113559j:plain"
      }}></img>{` // 男が 15 人で BBQ をすれば、当たり前のように肉の争奪戦が始まります。メシに上下関係なんてありません。`}</p>
    <p>{`日々オフィスにこもりストイックに仕事をする開発本部メンバーにとって、山に登り体を動かしたり、バーベキューで肉の取り合いをすることは良いリフレッシュになったのではないかと思います。`}</p>
    <h1>{`今後へ向けて`}</h1>
    <p>{`「リフレッシュ・コミュニケーション」がテーマであるものの、ただリフレッシュし、コミュニケーションを取るだけが今回の目的ではありません。
メドレーという会社が目指す未来を実現するために、開発本部の今後のロードマップを定めることも直近のテーマです。`}</p>
    <p>{`夜は、`}<a parentName="p" {...{
        "href": "https://marumo.net/gasshuku-plan001/"
      }}>{`まるも`}</a>{`にて「これまでの開発本部の道のり」と、そして「これからの開発本部がどうあるべき」か、
そして「株式会社メドレーが運営する 4 つのプロダクトをどういう気持ちで成長させていくか」ということに関して CTO の平山がその熱い胸の内を共有しました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20170208/20170208113659.jpg",
        "alt": "f:id:yamadagenki:20170208113659j:plain",
        "title": "f:id:yamadagenki:20170208113659j:plain"
      }}></img>{` // 平山の発表を真剣に聞くメンバーの様子。`}</p>
    <p>{`発表は、2015 年から 2017 年の現在にいたるまでのメドレー開発本部の動きがまとめられている資料で、個人的に今までやってきたことを思い出し感慨深く感じました。
中でも、熱い思いが込められたこの一枚のスライドがとても印象に残っています。
ちょっと、内緒で拝借してご紹介します。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20170207/20170207185100.png",
        "alt": "f:id:yamadagenki:20170207185100p:plain",
        "title": "f:id:yamadagenki:20170207185100p:plain"
      }}></img>{`
// 平山の思いが込められたスライド。しっかりとメンバーの胸に刻み込まれました。`}</p>
    <p>{`そのあとは、メンバー各々プロダクトへの思いや最新の技術の話題など、夜遅くまで真面目に、且つ面白く語り合っていました。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`今回はリフレッシュとコミュニケーションをテーマに、千葉県で合宿を行いました。`}</p>
    <p>{`鋸山で山登りをし、`}<a parentName="p" {...{
        "href": "https://marumo.net/gasshuku-plan001/"
      }}>{`まるも`}</a>{`で夜遅くまでプロダクトに対する思いについて語り合い、リフレッシュもコミュニケーションもしっかりできました。
帰りは若干眠そうでしたが、みなスッキリした顔で解散し、良い合宿だったなと思いました。
今後も定期的に合宿を行い、プロダクトへ対する思いを共有し、その思いを大切にしながら開発していきたいと思いました。`}</p>
    <h1>{`最後に`}</h1>
    <p>{`今回企画してくれた新居さん、ありがとうございます！！！`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/y/yamadagenki/20170208/20170208113720.jpg",
        "alt": "f:id:yamadagenki:20170208113720j:plain",
        "title": "f:id:yamadagenki:20170208113720j:plain"
      }}></img>{` // 恒例(にしていきたい)の到着直後の新居さんピース`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      